import Storage from '@utils/storage'
import isEmpty from 'lodash/isEmpty';
import Ajax from './Ajax';
import http from "@/commons/utils/ajax.js";


// const getTimestamp = () => http.ajax({url:`/merchantinfo/v1/login?`+new Date().getTime()});
const userLogin =param => http.ajax({url:`/merchantinfo/v1/login`,data:param, headers: { "content-type": "application/json;" }});


const managerLogin =param => http.ajax({url:`/merchant-manager/v1/managerLogin`,data:param, headers: { "content-type": "application/json;" }});

export default {
  // getTimestamp,
  userLogin,
  managerLogin
}
