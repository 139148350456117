import tabsPsd from '../el_tabs_password';
import tabsPhone from '../el_tabs_phone';
import Storage from "@/commons/utils/storage.js"
export default {
  components: {
    tabsPsd,
    tabsPhone,
  },
  data() {
    return {
      activeName: "first",
      label_n:'登录'
    };
  },
  computed: {},
  watch: {},
  created() {
    Storage.remove("menu");
    Storage.remove("isMenu");
    Storage.remove("user")
  },
  methods: {
    registerTit(e){
      this.label_n=e
    }
  },
};
