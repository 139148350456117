import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';
import { ElMessage } from 'element-plus'

var _CODE_STATUS_={
  CODE_UNKNOWN:-1,
  CODE_OK:0,
  CODE_NO_DATA:1,
  CODE_NO_LOGIN: 401,
  CODE_PARA_ERROR:2,
  CODE_CHILD_ERROR:3,
  CODE_ERROR:10,
  CODE_LOGIN_REQUIRE:401,
  CODE_NO_RIGHT:12,
  CODE_NEED_JUMP:13,
  CODE_INTER_ERR:14,
  CODE_LOGIN_ERR: 11002001,//商家不存在
  CODE_REGISTER_ERR: 500,
  CODE_REGISTZH_ERR: 400, // 注册用户名格式不对
  CODE_QUERYORDER_ERR: 901, //演示模式
  CODE_QUERYOCSNUM_ERR: 900,//次数不足（余额不足）
  CODE_ORDER_ERR: 11002005,//订单次数不足
  CODE_ADDORDER_ERR: 1000,
  CODE_ERJCODE_ERR: 11002026, //
  CODE_JYCODE_ERR: 11002028,//账号禁用
  CODE_YQMCODE_ERR: 11002003,//邀请码失效
  CODE_YQMYCODE_ERR: 11002026,//邀请码异常
  CODE_CHONGZHI_ERR: 11002030,//充值已完成，无需重复操作
  CODE_CHONGZHIQUDAO_ERR:1007001000,//支付渠道
  CODE_CHONGZHIWEIZHIFU_ERR:11002012,//订单充值未支付
  CODE_PAYNO_ERR:1007002000,//支付订单不存在
  CODE_ORDERPAY_SUCCESS:1007002002,//订单支付成功
  CODE_ZHANGHAO_DISABLED:11002029, //商户登录失败，账号被禁用
  CODE_KEY_DOES_NOT_EXIST: 1001003004,//密钥不存在
  CODE_KEY_NOMERCHANT: 11001001,
};
const instance = axios.create({baseURL:process.env.VUE_APP_API_BASEURL});
var isOpenLogin = false;
function ajax(){
  var options ={
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded;charset=UTF8',
    'X-Requested-With': 'XMLHttpRequest',"Authorization": localStorage.getItem('token') },
    data: {}
  };
  var successHandler = function(code, msg, data,resolve){
      resolve([code, msg, data]);
    },
    loginHandler=function(c,m,d){
      if(isOpenLogin)
        return
      isOpenLogin=true;
      // 管理端登录失效默认跳转到登录页
      if(localStorage.getItem('merchantLogin') == 'true'){
        window.location.href='/#/merchantLogin';
      }else{
        // 商家端
        window.location.href='';
      }
    },
    msgHandler=function(c,m,d){
      ElMessage.info(m);
    },
    jumpHandler=function(c,m,d){
      console.log("此处提示信息")
    },
    errorHandler = function(c,m,d){
      ElMessage.error(m);
    },
    then = function (responseMsg,resolve) {
      if(responseMsg) {
        let stutas=responseMsg.status;
        switch (stutas) {
          case 200 :
            let resData = responseMsg.data;
          if(resData.code == null){
            resData.code = 0;
          }
            let code = resData.code;
            let data = resData.data;
            let msg = resData.msg;
            switch (code) {
              case _CODE_STATUS_.CODE_OK:
              case _CODE_STATUS_.CODE_NO_DATA:
			        case _CODE_STATUS_.CODE_PARA_ERROR:
              case _CODE_STATUS_.CODE_LOGIN_ERR:
              case _CODE_STATUS_.CODE_REGISTER_ERR:
              case _CODE_STATUS_.CODE_ORDER_ERR:
              case _CODE_STATUS_.CODE_ADDORDER_ERR:
              case _CODE_STATUS_.CODE_ERJCODE_ERR:
              case _CODE_STATUS_.CODE_JYCODE_ERR:
              case _CODE_STATUS_.CODE_YQMCODE_ERR:
              case _CODE_STATUS_.CODE_YQMYCODE_ERR:
              case _CODE_STATUS_.CODE_REGISTZH_ERR:
              case _CODE_STATUS_.CODE_QUERYORDER_ERR:
              case _CODE_STATUS_.CODE_QUERYOCSNUM_ERR:
              case _CODE_STATUS_.CODE_KEY_DOES_NOT_EXIST:
              case _CODE_STATUS_.CODE_KEY_NOMERCHANT:
                successHandler(code, msg, data, resolve);
                break;
              case _CODE_STATUS_.CODE_ERROR:
              case _CODE_STATUS_.CODE_UNKNOWN:
              case _CODE_STATUS_.CODE_CHONGZHI_ERR:
              case _CODE_STATUS_.CODE_CHONGZHIQUDAO_ERR:
              case _CODE_STATUS_.CODE_CHONGZHIWEIZHIFU_ERR:
              case _CODE_STATUS_.CODE_PAYNO_ERR:
              case _CODE_STATUS_.CODE_ORDERPAY_SUCCESS:
              case _CODE_STATUS_.CODE_ZHANGHAO_DISABLED:
              // case _CODE_STATUS_.CODE_PARA_ERROR:
                errorHandler(code, msg, data);
                break;
                
              case _CODE_STATUS_.CODE_NO_LOGIN:
              case _CODE_STATUS_.CODE_LOGIN_REQUIRE:
                loginHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_NEED_JUMP:
                jumpHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_NO_RIGHT:
              case _CODE_STATUS_.CODE_CHILD_ERROR:
              case _CODE_STATUS_.CODE_INTER_ERR:
                msgHandler(code, msg, data);
                break;
            }
            break;
        }
      }
    };
  this.post=function(config){// 请求数据
    let config_=options;
    Object.assign(config_,options,config);
    if(!config_.url){
      console.log("url can not be null");
      return null;
    }
    try {
      if(config_.method.toLowerCase()=="get"){
        config_.params=config_.data;
      }else if(config_.method.toLowerCase()=="post"&&config_.headers["content-type"].indexOf("application/x-www-form-urlencoded")>=0){
        config_.data=qs.stringify(config_.data);
      }
      return new Promise(function(resolve,reject){
        config_.headers.Authorization = localStorage.getItem('token')
        return instance(config_)
          .then(res=>{
            then(res,resolve);
          }).catch(res=>{
            then(res,resolve)
          });
      })
    } catch (e) {
      console.log(e)
      return null;
    }
  };
  this.success=function(fn){
    successHandler = fn;
    return this;
  };
  this.error = function(fn){
    errorHandler = fn;
    return this;
  };
  this.login = function(fn){
    loginHandler = fn;
    return this;
  };
  this.jump = function(fn){
    jumpHandler = fn;
    return this;
  };
  this.msg = function(fn){
    msgHandler = fn;
    return this;
  };
}

function ajaxPut(){
  var options ={
    method: 'put',
    headers: { 'content-type': 'application/x-www-form-urlencoded;charset=UTF8',
    'X-Requested-With': 'XMLHttpRequest',"Authorization": localStorage.getItem('token') },
    data: {}
  };
  var successHandler = function(code, msg, data,resolve){
      resolve([code, msg, data]);
    },
    loginHandler=function(c,m,d){
      if(isOpenLogin)
        return
      isOpenLogin=true;
      if(localStorage.getItem('merchantLogin') == 'true'){
        window.location.href='/#/merchantLogin';
      }else{
        window.location.href='';
      }
    },
    msgHandler=function(c,m,d){
      ElMessage.info(m);
    },
    jumpHandler=function(c,m,d){
      console.log("此处提示信息")
    },
    errorHandler = function(c,m,d){
      ElMessage.error(m);
    },
    then = function (responseMsg,resolve) {
      if(responseMsg) {
        let stutas=responseMsg.status;
        switch (stutas) {
          case 200 :
            let resData = responseMsg.data;
          if(resData.code == null){
            resData.code = 0;
          }
            let code = resData.code;
            let data = resData.data;
            let msg = resData.msg;
            switch (code) {
              case _CODE_STATUS_.CODE_OK:
              case _CODE_STATUS_.CODE_NO_DATA:
              case _CODE_STATUS_.CODE_PARA_ERROR:
              case _CODE_STATUS_.CODE_LOGIN_ERR:
              case _CODE_STATUS_.CODE_REGISTER_ERR:
              case _CODE_STATUS_.CODE_ORDER_ERR:
              case _CODE_STATUS_.CODE_ADDORDER_ERR:
              case _CODE_STATUS_.CODE_ERJCODE_ERR:
              case _CODE_STATUS_.CODE_JYCODE_ERR:
              case _CODE_STATUS_.CODE_YQMCODE_ERR:
              case _CODE_STATUS_.CODE_YQMYCODE_ERR:
              case _CODE_STATUS_.CODE_REGISTZH_ERR:
              case _CODE_STATUS_.CODE_QUERYORDER_ERR:
              case _CODE_STATUS_.CODE_QUERYOCSNUM_ERR:
              successHandler(code, msg, data, resolve);
              break;
              case _CODE_STATUS_.CODE_ERROR:
              case _CODE_STATUS_.CODE_UNKNOWN:
              case _CODE_STATUS_.CODE_CHONGZHI_ERR:
              case _CODE_STATUS_.CODE_CHONGZHIQUDAO_ERR:
              case _CODE_STATUS_.CODE_CHONGZHIWEIZHIFU_ERR:
              case _CODE_STATUS_.CODE_PAYNO_ERR:
              case _CODE_STATUS_.CODE_ORDERPAY_SUCCESS:
              case _CODE_STATUS_.CODE_ZHANGHAO_DISABLED:
              // case _CODE_STATUS_.CODE_PARA_ERROR:
                errorHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_LOGIN_REQUIRE:
                loginHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_NEED_JUMP:
                jumpHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_NO_RIGHT:
              case _CODE_STATUS_.CODE_CHILD_ERROR:
              case _CODE_STATUS_.CODE_INTER_ERR:
                msgHandler(code, msg, data);
                break;
            }
            break;
        }
      }
    };
  this.put=function(config){// 请求数据
    let config_=options;
    Object.assign(config_,options,config);
    if(!config_.url){
      console.log("url can not be null");
      return null;
    }
    try {
      if(config_.method.toLowerCase()=="get"){
        config_.params=config_.data;
      }else if(config_.method.toLowerCase()=="put"&&config_.headers["content-type"].indexOf("application/x-www-form-urlencoded")>=0){
        config_.data=qs.stringify(config_.data);
      }
      return new Promise(function(resolve,reject){
        config_.headers.Authorization = localStorage.getItem('token')
        return instance(config_)
          .then(res=>{
            then(res,resolve);
          }).catch(res=>{
            then(res,resolve)
          });
      })
    } catch (e) {
      console.log(e)
      return null;
    }
  };
  this.success=function(fn){
    successHandler = fn;
    return this;
  };
  this.error = function(fn){
    errorHandler = fn;
    return this;
  };
  this.login = function(fn){
    loginHandler = fn;
    return this;
  };
  this.jump = function(fn){
    jumpHandler = fn;
    return this;
  };
  this.msg = function(fn){
    msgHandler = fn;
    return this;
  };
}


function ajaxGet(){
  var options ={
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded;charset=UTF8',
    'X-Requested-With': 'XMLHttpRequest',"Authorization": localStorage.getItem('token') },
    data: {}
  };
  var successHandler = function(code, msg, data,resolve){
      resolve([code, msg, data]);
    },
    loginHandler=function(c,m,d){
      if(isOpenLogin)
        return
      isOpenLogin=true;
      debugger
      if(localStorage.getItem('merchantLogin') == 'true'){
        window.location.href='/#/merchantLogin';
      }else{
        window.location.href='';
      }
      
    },
    msgHandler=function(c,m,d){
      ElMessage.info(m);
    },
    jumpHandler=function(c,m,d){
      console.log("此处提示信息")
    },
    errorHandler = function(c,m,d){
      ElMessage.error(m);
    },
    then = function (responseMsg,resolve) {
      if(responseMsg) {
        let stutas=responseMsg.status;
        switch (stutas) {
          case 200 :
            let resData = responseMsg.data;
          if(resData.code == null){
            resData.code = 0;
          }
            let code = resData.code;
            let data = resData.data;
            let msg = resData.msg;
            switch (code) {
              case _CODE_STATUS_.CODE_OK:
              case _CODE_STATUS_.CODE_NO_DATA:
              case _CODE_STATUS_.CODE_PARA_ERROR:
              case _CODE_STATUS_.CODE_LOGIN_ERR:
              case _CODE_STATUS_.CODE_REGISTER_ERR:
              case _CODE_STATUS_.CODE_ORDER_ERR:
              case _CODE_STATUS_.CODE_ADDORDER_ERR:
              case _CODE_STATUS_.CODE_ERJCODE_ERR:
              case _CODE_STATUS_.CODE_JYCODE_ERR:
              case _CODE_STATUS_.CODE_YQMCODE_ERR:
              case _CODE_STATUS_.CODE_YQMYCODE_ERR:
              case _CODE_STATUS_.CODE_REGISTZH_ERR:
              case _CODE_STATUS_.CODE_QUERYORDER_ERR:
              case _CODE_STATUS_.CODE_QUERYOCSNUM_ERR:
              successHandler(code, msg, data, resolve);
              break;
              case _CODE_STATUS_.CODE_ERROR:
              case _CODE_STATUS_.CODE_UNKNOWN:
              case _CODE_STATUS_.CODE_CHONGZHI_ERR:
              case _CODE_STATUS_.CODE_CHONGZHIQUDAO_ERR:
              case _CODE_STATUS_.CODE_CHONGZHIWEIZHIFU_ERR:
              case _CODE_STATUS_.CODE_PAYNO_ERR:
              case _CODE_STATUS_.CODE_ORDERPAY_SUCCESS:
              case _CODE_STATUS_.CODE_ZHANGHAO_DISABLED:
              // case _CODE_STATUS_.CODE_PARA_ERROR:
                errorHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_LOGIN_REQUIRE:
                loginHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_NEED_JUMP:
                jumpHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_NO_RIGHT:
              case _CODE_STATUS_.CODE_CHILD_ERROR:
              case _CODE_STATUS_.CODE_INTER_ERR:
                msgHandler(code, msg, data);
                break;
            }
            break;
        }
      }
    };
  this.get=function(config){// 请求数据
    let config_=options;
    Object.assign(config_,options,config);
    if(!config_.url){
      console.log("url can not be null");
      return null;
    }
    try {
      if(config_.method.toLowerCase()=="get"){
        config_.params=config_.data;
      }else if(config_.method.toLowerCase()=="put"&&config_.headers["content-type"].indexOf("application/x-www-form-urlencoded")>=0){
        config_.data=qs.stringify(config_.data);
      }
      return new Promise(function(resolve,reject){
        config_.headers.Authorization = localStorage.getItem('token')
        return instance(config_)
          .then(res=>{
            then(res,resolve);
          }).catch(res=>{
            then(res,resolve)
          });
      })
    } catch (e) {
      console.log(e)
      return null;
    }
  };
  this.success=function(fn){
    successHandler = fn;
    return this;
  };
  this.error = function(fn){
    errorHandler = fn;
    return this;
  };
  this.login = function(fn){
    loginHandler = fn;
    return this;
  };
  this.jump = function(fn){
    jumpHandler = fn;
    return this;
  };
  this.msg = function(fn){
    msgHandler = fn;
    return this;
  };
}

export const  ajaxHandler=ajax;
export const  ajaxHandlerPut=ajaxPut;
export default {
  ajaxHandler (){
    return new ajax();
  },
  ajaxHandlerPut (){
    return new ajaxPut();
  },
  ajaxHandlerGet (){
    return new ajaxGet();
  },
  ajax:function(config){
    return new ajax().post(config);
  },
  ajaxPut:function(config){
    return new ajaxPut().put(config);
  },
  ajaxGet:function(config){
    return new ajaxGet().get(config);
  }
}
// example
/**
 * ajaxHandler().success(function(c,m,d){// your code here...}).post({});
 * ajaxHandler().post({}).then(res = > {c==res[0],m==res[1],d==res[2]});
 *
 * ajax({}).then(res = > {c==res[0],m==res[1],d==res[2]});
 * */
