import md5 from 'md5';
import user from '../../../../../api/user.js';
import Verify from "@/components/verifition/Verify";
import { log } from "echarts/lib/util/log";
import { Avatar, Lock } from "@element-plus/icons-vue";
import rules from "@/components/EditForm/js/rules.js";
import Storage from "@/commons/utils/storage.js"
export default {
  components: {
    Verify
  },
  data() {
    return {
      Avatar,
      Lock,
      forgetChecked: false,
      typepsd1: "password",
      typepsd2: "password",
      typepsd3: "password",
      typepsd4: "password",
      typepsd5: "password",
      flag: true,
      flag2: true,
      flag3: true,
      register: false,
      forgetShow: false,
      imgUrl: this.BASEPATH + "/base/authoriza/user/imgcode.htm?d=" + new Date().getTime(),
      imgsrc: require("@/assets/images/denglu_ic_biyan.png"),
      imgsrc2: require("@/assets/images/denglu_ic_biyan.png"),
      imgsrc3: require("@/assets/images/denglu_ic_biyan.png"),
      imgsrc4: require("@/assets/images/denglu_ic_biyan.png"),
      imgsrc5: require("@/assets/images/denglu_ic_biyan.png"),
      ruleForm: {
        name: "",
        password: "",
        // number: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 4, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
      registerForm: {
        inviteCode: '',//邀请码
      },
      registerRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          // { min: 3, message: "长度在 3 到 5 个字符", trigger: "blur" },
          { validator: rules.FormValidate.Form().validateUserName, message: "请输入格式为字母/数字/字母+数字/数组+字母(不包含特殊符号)", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: rules.FormValidate.Form().validatePhone, message: "请输入正确的手机号", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 4, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },

      forgotForm: {},
      forgotRules: {
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 4, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        code:[
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ]
      },
      registerDisabled: false,
      codeDisabled: false,
      dis: false,
      show: true,
      isGrey: false, //按钮样式
      timer: null, //设置计时器,
      count: "",
      forgotDisabled: false,//重置密码
    };
  },
  computed: {},
  watch: {},
  //创建成功后来定义江畔事件//
  created() {

    document.onkeydown = (e) => {
      if (window.event === undefined) {
        var key = e.keyCode
      } else {
        // eslint-disable-next-line no-redeclare
        var key = window.event.keyCode
      }
      if (key === 13) {
        this.submitForm()
      }
    }
    this.getRegister()
    this.getStorage();
    localStorage.setItem('merchantLogin', false);
    localStorage.setItem('activeIndex','/order')
  },
  methods: {
    getRegister(){
      let url = window.location.href;
      this.register =  Boolean(this.getQueryString(url,'register')); //获取地址栏参数
      if(this.register == true){
        this.registerFn();//跳转注册
      }
      this.registerForm.inviteCode = this.getQueryString(url,'inviteCode').replace(/#\/+/g, "");
    },
    // 获取地址栏参数
    getQueryString(url,paraName) {
      let arrObj = url.split("?");
      if (arrObj.length > 1) {
        let arrPara = arrObj[1].split("&")
        let arr;
        for (let i in arrPara) {
          arr = arrPara[i].split("=")
          if (arr != null && arr[0] == paraName) {
            if(arr[0] == 'inviteCode' && arrPara[1].indexOf('==') >-1){
              return arr[1] +'=='
            }else{
              return arr[1]
            }
            
          }
        }
        return "="
      }else {
        return ""
      }
    },
    success(data) {
      let this_ = this;

      // data 返回的二次验证参数
      let param = this.buildingParams();
      if (data) {
        param.captchaVerification = data.captchaVerification
      }
      if (param && param.captchaVerification) {
        // user.getTimestamp().then(res => {
        //   var time = res[2];
        //   param.date = time;
        //   param.password = md5(md5(param.password).toUpperCase() + param.date).toUpperCase();

        user.userLogin(param).then(res => {
          if (this_.forgetChecked == true) {
            this_.setStorage(this_.ruleForm.username, this_.ruleForm.password, 7);
          } else {
            this_.clearStorage();
          }
          if (res[2] && res[2].accessToken) {
            localStorage.setItem('token', res[2].accessToken);
          }

          if (res[0] == 0) {
            this_.$store.state.tabsPage = [{
              title: '商家信息',
              path: '/base/main/desk/desk'
            }];
            this_.$store.state.TabsValue = '/order';
            this_.$store.state.noticeShow = true;
            localStorage.setItem('activeIndex','/order');
            this_.$router.push({
              // path:"/index",
              path: "/order"
            });
            // this_.$store.state.tabsPage = [
            //   { title: '控制台', name: '/base/main/desk/desk' },
            // ];
            // this_.$store.state.TabsValue = '/base/main/desk/desk';
            // this_.$store.state.noticeShow = true;
            // this_.$router.push({
            //   // path:"/index",
            //   path: "/base/main/desk/desk"
            // });
          } else {
            this_.$message.warning(res[1])
          }
        }).catch(err =>{
          this_.$message.warning(err)
        })
        // })
        //当mode="pop"时,调用组件实例的show方法显示组件
        // this_.$refs.verify.show();
      }
    },
    buildingParams() {
      let params = {
        username: this.ruleForm.username,
        password: this.ruleForm.password,
        // imgCode:  this.ruleForm.number
        captchaVerification: ""
      }
      return params;
    },
    //  显示密码加密密码 end
    handleForget() {
      this.forgetShow = true;
      this.$emit('registerTit', '修改密码')
    },
    registerFn() {
      this.register = true;
      this.$emit("registerTit", '注册')
    },
    registerSuccess(data) {
      let this_ = this;
      // this_.registerForm.inviteCode = localStorage.getItem('inviteCode');
      if (data) {
        this_.registerForm.captchaVerification = data.captchaVerification;
        if (this_.registerForm.password != this_.registerForm.confirmPassword) {
          this_.$message({
            type: 'warning',
            message: '请检查两次密码是否一致！'
          });
          return false;
        } else {
          this_.registerDisabled = true;
          this.http.ajaxHandler().success(function (code, msg, data) {
            if (code == 0) {
              this_.$message({
                type: 'success',
                message: msg
              });
              this_.$emit('registerTit', '登录')
              this_.forgetShow = false;
              this_.register= false;
              this_.registerDisabled = false;
              this_.$router.push('/')
            } else {
              this_.$message({
                type: 'warning',
                message: msg
              });
              this_.registerDisabled = false;
            }
          }).error(function (code, msg) {
            this_.$message.error(msg);
            this_.registerDisabled = false;
          }).post({ url: '/merchantinfo/v1/register', data: this_.registerForm, headers: { "content-type": "application/json;" } });
        }
      }
      
    },

    async registerBtn() {
      const valid = await this.$refs.registerForm.validate()
      if (!valid) {
        return;
      } else {
        this.registerSuccess()
        this.$refs.verifyRegister.show();
      }

    },
    async forgotBtn(){
      const valid = await this.$refs.forgotForm.validate()
      if (!valid) {
        return;
      } else {
        this.forgotSuccess()
        this.$refs.verify.show();
      }
    },
    forgotSuccess(data){
      let this_ = this;
      if (data) {
        this_.forgotForm.captchaVerification = data.captchaVerification;
        if (this_.forgotForm.password != this_.forgotForm.confirmPassword) {
          this_.$message({
            type: 'warning',
            message: '请检查两次密码是否一致！'
          });
          return false;
        } else {
          // this_.forgotDisabled = true;
          this.http.ajaxHandler().success(function (code, msg, data) {
            if (code == 0) {
              this_.$message({
                type: 'success',
                message: msg
              });
              this_.$emit('registerTit', '登录')
              this_.forgetShow = false;
              this_.register= false;
              this_.forgotDisabled = false;
            } else {
              this_.$message({
                type: 'warning',
                message: msg
              });
              this_.forgotDisabled = false;
            }
          }).error(function (code, msg) {
            this_.$message.error(msg);
            this_.forgotDisabled = false;
          }).post({ url: '/merchantinfo/v1/updatePwd', data: this_.forgotForm, headers: { "content-type": "application/json;" } });
        }
      }
     
    },
    forgetGetCode(scene) {
      if (!this.forgotForm.mobile) {
        this.$message({
          type: 'warning',
          message: '请输入手机号'
        });
        return;
      } else {
        let TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.isGrey = true;
          this.show = false;
          this.dis = true;
          this.getCodeFn(scene);
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.dis = false;
              this.isGrey = false;
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    getCodeFn(scene){
      let params = {
        scene: scene,  //注册 11 忘记密码13
        mobile: this.forgotForm.mobile
      }
      let this_ = this;
      this.http.ajaxHandler().success(function (code, msg, data) {
        if (code == 0) {
          this_.$message({
            type: 'success',
            message: '验证码发送成功'
          });
        } else {
          this_.$message({
            type: 'warning',
            message: '验证码发送失败'
          });
        }
      }).error(function (code, msg) {
        this_.$message.error(msg);
      }).post({
        url: '/merchantinfo/v1/sendSmsCode', data: params, headers: { "content-type": "application/json;" }
      });
    },
    // 注册密码
    getRegisterCode(scene) {
      if (!this.registerForm.mobile) {
        this.$message({
          type: 'warning',
          message: '请输入手机号'
        });
        return;
      } else {
        let TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.isGrey = true;
          this.show = false;
          this.dis = true;
          this.getRegisterCodeFn(scene);
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.dis = false;
              this.isGrey = false;
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    getRegisterCodeFn(scene) {
      let params = {
        scene: scene,  //注册 11 忘记密码13
        mobile: this.registerForm.mobile
      }
      let this_ = this;
      this.http.ajaxHandler().success(function (code, msg, data) {
        if (code == 0) {
          this_.$message({
            type: 'success',
            message: '验证码发送成功'
          });
        } else {
          this_.$message({
            type: 'warning',
            message: '验证码发送失败'
          });
        }
      }).error(function (code, msg) {
        this_.$message.error(msg);
      }).post({
        url: '/merchantinfo/v1/sendSmsCode', data: params, headers: { "content-type": "application/json;" }
      });
    },
    goLogin() {
      this.register = false;
      this.forgetShow = false;
      this.$emit("registerTit", '登陆')
    },
    submitForm(formName) {
      // if (this.ruleForm.name.trim() == "") {
      //   this.$message.error('请输入账号');
      //   return
      // } else if (this.ruleForm.password.trim() == "") {
      //   this.$message.error('请输入密码');
      //   return
      // }
      this.success()
      this.$refs.verify.show();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // changeImg(){
    //   this.imgUrl=this.BASEPATH+"/base/authoriza/user/imgcode.htm?d="+new Date().getTime();
    //   this.ruleForm.number=""
    // },
    //设置Storage
    setStorage(c_name, c_pwd, exdays) {
      // var exdate = new Date(); //获取时间
      // exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      localStorage.setItem('username', c_name);
      localStorage.setItem('password', c_pwd);
    },
    //读取Storage
    getStorage: function () {
      if (localStorage.getItem('username')) {
        this.ruleForm.username = localStorage.getItem('username');
        this.ruleForm.password = localStorage.getItem('password');
        this.forgetChecked = true;
      }
    },
    //清除Storage
    clearStorage: function () {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    },
    
    //  显示密码加密密码 start
    handleclose() {
      this.flag = !this.flag;
      if (this.flag) {
        this.typepsd1 = "password";
        this.imgsrc = require("@/assets/images/denglu_ic_biyan.png");
      } else {
        this.typepsd1 = "text";
        this.imgsrc = require("@/assets/images/denglu_ic_zhangkai.png");
      }
    },
    handleclose2() {
      this.flag2 = !this.flag2;
      if (this.flag2) {
        this.typepsd2 = "password";
        this.imgsrc2 = require("@/assets/images/denglu_ic_biyan.png");
      } else {
        this.typepsd2 = "text";
        this.imgsrc2 = require("@/assets/images/denglu_ic_zhangkai.png");
      }
    },
    handleclose3() {
      this.flag3 = !this.flag3;
      if (this.flag3) {
        this.typepsd3 = "password";
        this.imgsrc3 = require("@/assets/images/denglu_ic_biyan.png");
      } else {
        this.typepsd3 = "text";
        this.imgsrc3 = require("@/assets/images/denglu_ic_zhangkai.png");
      }
    },
    handleclose4() {
      this.flag4 = !this.flag4;
      if (this.flag4) {
        this.typepsd4 = "password";
        this.imgsrc4 = require("@/assets/images/denglu_ic_biyan.png");
      } else {
        this.typepsd4 = "text";
        this.imgsrc4 = require("@/assets/images/denglu_ic_zhangkai.png");
      }
    },
    handleclose5() {
      this.flag5 = !this.flag5;
      if (this.flag5) {
        this.typepsd5 = "password";
        this.imgsrc5 = require("@/assets/images/denglu_ic_biyan.png");
      } else {
        this.typepsd5 = "text";
        this.imgsrc5 = require("@/assets/images/denglu_ic_zhangkai.png");
      }
    },

  }
};

