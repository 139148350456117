import Login from '../views/base/main/login/Login.vue'

export const LoginRouter = {
  path: '/login/Login',
  name: 'login',
  meta: {
    title: '登录',
    ignore: true
  },
  component: Login
}
export default [LoginRouter]
